<template>
  <div class="headers">
	  <div class="box-title">
		  <span v-for="(item,index) in tuils" :key='index' :class="filg==index?'active':''" @click="diaj(index)">{{item.text}}</span>
	  </div>
	  <div class="item-box" v-if="filg==0">
	    <el-button size="small" icon="el-icon-plus" class="plan-btn" @click="handleAddPlan">{{ $t("lang.AddScheme") }}</el-button>
	    <div class="plan-item" v-for="(item,index) in planData" :key="index">
			<div class="plan-items">
				<el-form ref="planForm" :model="item">
				  <el-form-item :label="$t('lang.SchemeName')" class="planName-box">
				    <el-input style="width:3.7rem" v-model="item.name" clearable></el-input>
				  </el-form-item>
				  <div class="address-item" v-for="(obj,idx) in item.nameUrls" :key="idx">
				    <el-form-item :label="$t('lang.streamingAddress')">
				      <el-input style="width:3.2rem" v-model="obj.url" clearable @blur="validateUrl(obj.url)"></el-input>
				    </el-form-item>
				    <div class="del-icon" @click="handleDelPlanAddress(index,idx,item.id)">
				      <img src="../../../assets/imgs/shanchu01.png" alt="">
				    </div>
				  </div>
				</el-form>
				<el-button size="small" type="plain" class="add-address-btn" icon="el-icon-plus" @click="handleAddAddress(index)">{{$t('lang.Addaddress')}}</el-button>
				<div class="bottom-btn">
				  <el-button size="small" class="plan-btn" @click="submitPlan(index)">{{$t('lang.SaveScheme')}}</el-button>
				  <el-button size="small" class="plan-btn" @click="delPlan(index,item.id)">{{$t('lang.DeleteScheme')}}</el-button>
				</div>
			</div>
	    </div>
	  </div>
	  <div class="item-box" v-else>
	    <div class="plan-item">
			<div class="plan-items">
				<el-form :model="frequentPlan">
					<div class="address-item" v-for="(item,index) in frequentPlan.nameUrls" :key="index">
					  <el-form-item :label="$t('lang.streamingAddress')">
						<el-input style="width:3.20rem" v-model="item.url" @blur="validateUrl(item.url)"></el-input>
					  </el-form-item>
					  <div class="del-icon" @click="handleDelFrequentAddress(index,item.id)">
						<img src="../../../assets/imgs/shanchu01.png" alt="">
					  </div>
					</div>
				</el-form>
				<el-button size="small" type="plain" class="add-address-btn" icon="el-icon-plus" @click="handleAddFrequentAddress">{{$t('lang.Addaddress')}}</el-button>
				<div class="bottom-btn">
					<el-button size="small" class="plan-btn" @click="submitFrequentAddress">{{$t('lang.SaveAddress')}}</el-button>
				</div>
				</div>
			</div>
	    </div>
	  </div>
  </div>
</template>

<script>

import * as plan from '@/api/apis';

  export default {
    data(){
      return{
        planData:[],
        frequentPlan:{
          email: "",
          name: "",
          nameUrls: []
        },
		filg:0
      }
    },
	computed:{
		tuils(){
			return [
				  {text:this.$t('lang.Streamingscheme')},
				  {text:this.$t('lang.FrequentlyStreamAddress')}
				]
		}
	},
    created(){
      this.getAllPlan()
    },
    methods:{
		diaj(index){
			this.filg=index
		},
      handleBackHome(){
        this.$router.push('/nav/user/equipment')
      },
      //获取所有方案
      async getAllPlan(){
        let email = JSON.parse(sessionStorage.getItem("user")).email;
        let { data } = await plan.solList({email:email});
        if(data !== null){
          this.planData = data.filter(item => item.name !== null);
          let frequentPlan = data.filter(item => item.name === null)
          if(frequentPlan.length){
            this.frequentPlan = frequentPlan[0]
          }else{
            this.frequentPlan = {
              email: "",
              name: "",
              nameUrls: []
            }
          }
        }else{
          this.planData = [];
          this.frequentPlan = {
            email: "",
            name: "",
            nameUrls: []
          }
        }
      },
      validateUrl(val){
        // let urlRegex = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~/])+$/;
        let urlRegex = /^((https|http|ftp|rtsp|mms|rtp|artc|rtmp|RTMP|rtmp|RTMPS|rtmps|SRT|srt|ARTC|artc)?:\/\/|www\.)[^\s]+/;
        if(val && !urlRegex.test(val)){
          this.$message.error(this.$t('lang.Pleaseenterthecorrect'));
          return
        }
      },
      //添加方案
      handleAddPlan(){
        this.planData.push({
          email: "",
          name: "",
          nameUrls: [
              {
                name: "",
                url: ""
              }
          ]
        })
      },
      // 保存方案
      submitPlan(index){
        this.planData[index].email = JSON.parse(sessionStorage.getItem("user")).email;
        let params = this.planData[index];
        let canSubmit = true;
        let urlRegex = /^((https|http|ftp|rtsp|mms|rtp|artc|rtmp|RTMP|rtmp|RTMPS|rtmps|SRT|srt|ARTC|artc)?:\/\/|www\.)[^\s]+/;
        for(let item of params.nameUrls){
          if(!urlRegex.test(item.url)){
            canSubmit = false;
          }
        }
        if(canSubmit){
          plan.solution(params).then(res => {
            this.$message({
              message: this.$t('lang.addsuccess'),
              type: 'success'
            });
            this.getAllPlan()
          })
        }else{
          this.$message.error(this.$t('lang.Pleaseenterthecorrect'));
          return
        }
        
      },
      // 删除方案
      delPlan(index,id){
        if(!id){
          this.planData.splice(index,1)
        }else{
          this.$confirm( this.$t('lang.ThisActionPermanentlyDeletesTheSchema') + " " + this.$t('lang.WhetherOrNotToContinue'), this.$t('lang.Tips'), {
            confirmButtonText: this.$t('lang.determine'),
            cancelButtonText: this.$t('lang.cancel'),
            type: 'warning'
          }).then(() => {
            plan.deleteSol({id1:id,id2:""}).then(() => {
              this.$message({
                message: this.$t('lang.deletessuccess'),
                type: 'success'
              });
              this.getAllPlan()
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('lang.CanceledDelete')
            });          
          });
        }
      },
       //新增地址
      handleAddAddress(index){
        if(this.planData[index].nameUrls.length < 4){
          this.planData[index].nameUrls.push({name:"",url:""})
        }else{
			this.$message.error(this.$t('lang.vbs'))
		}
      },
      //删除方案地址
      handleDelPlanAddress(index,idx,id){
        if(this.planData[index].nameUrls.length !== 1){
          this.planData[index].nameUrls.splice(idx,1)
        }/* else{
          this.$confirm(this.$t('lang.ThisActionPermanentlyDeletesTheSchema') + " " + this.$t('lang.WhetherOrNotToContinue'), this.$t('lang.Tips'), {
            confirmButtonText: this.$t('lang.determine'),
            cancelButtonText: this.$t('lang.cancel'),
            type: 'warning'
          }).then(() => {
            plan.deleteSol({id1:id,id2:""}).then(() => {
              this.$message({
                message:  this.$t('lang.deletessuccess'),
                type: 'success'
              });
              this.getAllPlan()
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('lang.CanceledDelete')
            });          
          });
        } */
      },
      //添加常用地址
      handleAddFrequentAddress(){
        if(this.frequentPlan.nameUrls.length < 4){
          this.frequentPlan.nameUrls.push({name:"",url:""})
        }else{
			this.$message.error(this.$t('lang.vbs'))
		}
      },
      //删除常用推流地址
      handleDelFrequentAddress(index,id){
        if(!id){
          this.frequentPlan.nameUrls.splice(index,1)
        }else{
          this.$confirm(this.$t('lang.ThisActionPermanentlyDeletesTheAddress') + " " + this.$t('lang.WhetherOrNotToContinue'), this.$t('lang.Tips'), {
            confirmButtonText: this.$t('lang.determine'),
            cancelButtonText: this.$t('lang.cancel'),
            type: 'warning'
          }).then(() => {
            plan.deleteSol({id1:"",id2:id}).then(() => {
              this.$message({
                message:  this.$t('lang.deletessuccess'),
                type: 'success'
              });
              this.getAllPlan()
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('lang.CanceledDelete')
            });          
          });
        }
      },
      // 保存常用推流地址
      submitFrequentAddress(){
        this.frequentPlan.email = JSON.parse(sessionStorage.getItem("user")).email;
        let canSubmit = true;
        let urlRegex = /^((https|http|ftp|rtsp|mms|rtp|artc|rtmp|RTMP|rtmp|RTMPS|rtmps|SRT|srt|ARTC|artc)?:\/\/|www\.)[^\s]+/;
        for(let item of this.frequentPlan.nameUrls){
          if(!urlRegex.test(item.url)){
            canSubmit = false;
          }
        }
        if(canSubmit){
          plan.solAdd(this.frequentPlan).then(res => {
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.getAllPlan()
          })
        }else{
          this.$message.error(this.$t('lang.Pleaseenterthecorrect'));
          return
        }
      }
    }
  }
</script>

<style lang="less" scoped>
/*  .plan-container{
    width: 100%;
    height: 100%;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    .plan-box{
      width: 70%;
      height: 100%;
      overflow: auto;
      background-color: #fff;
      .box-title{
        font-size: .24rem;
        color: #666;
        padding:.33rem 0 .38rem .65rem;
      }
      .plan-item-box{
        padding-left: .72rem;
        display: flex;
        margin-bottom: .50rem;
        .plan-item-box-title{
          font-size: .18rem;
          color: #666;
          padding-top: .10rem;
          padding-right: .15rem;
        }
        .item-box{
          flex: 1;
           
          .plan-item{
            width: 80%;
            margin-top: .20rem;
            .planName-box{
              padding: .15rem;
              background-color: #EBEBEB;
              margin-bottom: 0px;
              /deep/.el-input{
                  .el-input__inner{
                    background-color: #EBEBEB;
                    border-color:#CDCDCD;
                  }
                }
            }
            
          }
        }
       
      }
    }
  }

  .plan-btn{
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    background-color:#0A0F30;
    border-color: #0A0F30;
  } */
  .headers{
	  width: 100%;
	  background-color: #ddd;
	  padding: 20px;
	  overflow: auto;
	  display: flex;
	  flex-flow: column;
	  align-items: center;
	  .box-title{
	    font-size: .24rem;
	    color: #666;
		span{
			display: inline-block;
			padding: 10px;
			margin-right: 20px;
		}
	  }
	  .item-box{
		  margin-top: 10px;
		 background-color: #fff;
		 margin-left: 100px;
		 padding-left: 50px;
		 padding-bottom: 30px;
		 display: flex;
		 flex-flow: column;
		 justify-content: flex-start;
		 width: 50%;
		 box-sizing: border-box;
		 .el-button{
			 margin-top: 30px;
			 width: 150px;
		 }
	    .plan-item{
	      margin-top: 20px;
		  .plan-items{
			  display: flex;
			  flex-flow: column;
			  justify-content: flex-start;
		  	  border: 1px solid #8C939D;
		  	  width: 80%;
		  	  border-radius: 4px;
			  .planName-box{
			    margin-bottom: 22px;
			    /deep/.el-input{
			        .el-input__inner{
			          background-color: #EBEBEB;
			          border-color:#CDCDCD;
			        }
			      }
			  }
			  .el-form{
				  padding: .2rem .2rem 0 .2rem;
			  }
			  .address-item{
			    display: flex;
			    // justify-content: space-between;
			    .el-form-item{
			       display: flex;
			    }
			    .del-icon{
			      width: .40rem;
			      height: .40rem;
			      border-radius: 50%;
			      background-color: #0A0F30;
			      text-align: center;
			      margin-left: .13rem;
			      position: relative;
			      img{
			        width: .25rem;
			        height: .26rem;
			        position: absolute;
			        left: .08rem;
			        top: .06rem;
			      }
			    }
			  
			  }
			  .add-address-btn{
				width: 1rem;
			    border-color:#DCDFE6;
				margin-bottom: .2rem;
				margin-top: 0;
				margin-left: .2rem;
			  }
			  .bottom-btn{
				border-top: 1px solid #8C939D;
			    display: flex;
			    justify-content: flex-end;
			    padding-right: .5rem;
				padding-bottom: .2rem;
				.el-button{
					width: 1rem;
					margin-top: .2rem;
				}
			  }
		  }
	    }
	  }
  }
  .active{
	  color:sandybrown;
  }
  .address-box{
    border:1px solid #B2B2B2;
	border-radius: 4px;
    width: 80%;
	div{
		.address-item{
		  display: flex;
		  
		  .el-form-item{
		     display: flex;
		  }
		  .del-icon{
		    width: .40rem;
		    height: .40rem;
		    border-radius: 50%;
		    background-color: #0A0F30;
		    text-align: center;
		    margin-left: .10rem;
		    position: relative;
		    img{
		      width: .25rem;
		      height: .26rem;
		      position: absolute;
		      left: .08rem;
		      top: .06rem;
		    }
		  }
		}
	}
    .add-address-btn{
      margin-left: .93rem;
      color: #0A0F30;
      border-color:#0A0F30;
      margin-bottom: 0;
    }
    .bottom-btn{
      display: flex;
      justify-content: flex-end;
      padding: .20rem 0;
      border-top: 1px solid #EBEBEB;
      padding-right: .10rem;
    }
  }
</style>